<template>
  <v-container>
    <Sidebar />
    <div class="text-center" style="margin-bottom:4em; margin-top:9em">
      <vue-qr
        :text="qrCode"
        qid="testid"
        :size="150"
      ></vue-qr>
    </div>
    <div class="text-center">
      <span>ロッカーキー: {{ orderDetail.locker_key }}</span>
    </div>
    <div class="d-flex justify-space-around flex-column mx-7">
      <!-- <div class="mt-7" >
        ※{{ $t("message.label-note") }}※
      </div> -->
      <div
        class="ms-5"
      >
        ・保管期間（およそ24時間）を過ぎると商品はロッカーから回収されます。
        商品回収後は購入店舗でおお受け取りとなります。
      </div>
      <!-- <div
        class="ms-5"
      >
        ・保管期間を過ぎると商品はロッカーから回収されます。
      </div> -->
    </div>
    <div class="d-flex justify-end mt-5 pt-5">
      <v-btn
        color="#ff0090"
        class="me-2 white--text"
        depressed
        @click="$router.go(-1)"
      >
        <v-icon left dark>
          mdi-chevron-left
        </v-icon>
        {{ $t("message.back") }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
// import QrcodeVue from "qrcode.vue";
// import VueQrcode from '@vue-qrcode'
import VueQr from "vue-qr";
import Sidebar from "../components/Sidebar.vue";
import Barcode from "vue-barcode";
import { mdiArrowLeft } from "@mdi/js";
export default {
  name: "LockerKey",
  components: {
    Sidebar,
    barcode: Barcode,
    VueQr,
    // QrcodeVue:QrcodeVue,
    // VueQrcode:VueQrcode,
  },
  data() {
    return {
      iconArrowLeft: mdiArrowLeft,
      value: "asdfasdf",
      size: 300,
      qrCode: this.$router.history.current.params.lockerKey
    };
  },
  computed: {
    orderDetail() {
      return this.$store.state.order_module.orderDetail;
    },
    locker_key() {
      return "12345657";
    },
  },
  created() {
    this.dispatchOrderDetail();
  },
  methods: {
    test(dataUrl, id) {
    },
    async dispatchOrderDetail() {
      let id = this.$router.history.current.params.id;
      // this.qrCode = parseInt(this.$router.history.current.params.lockerKey)
      await this.$store.dispatch("order_module/fetchOrderDetail", id);

    },
  },
};
</script>
<style>
.barcode .vue-barcode-element {
  width: 380px !important;
  height: 100px !important;
  justify-content: center !important;
  align-content: center !important;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
svg g text {
  font-weight: bold;
}
.row-header {
  background-color: white;
  height: 36px;
  width: 100%;
  margin-top: -15px;
  font-size: 24px;
  font-weight: 500;
}
</style>
